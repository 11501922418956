import { Link } from "gatsby-plugin-react-i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import Button from "~/ui/Button";

import heroBorder from "~/images/graphics/hero-border.png";
import chevronRightIcon from "~/images/icons/chevron-right.svg";

import { usePrepareData } from "~/hooks/prepare/usePrepareData";
import { numberWithCommas } from "~/utils/number";
import { IFarmingPool } from "~/entity";

type IFarmSlide = {
  pair: string;
  farmToken: string;
  baseToken: string;
  origin: string;
  apy: number;
  openPositionUrl: string;
};

type Pool = {
  sourceName: string;
  tvl: string;
  bestApr: number;
  bestApy: number;
  openPositionUrl: string;
};

const FarmComponent = ({ farm }: { farm: IFarmingPool }) => {
  const [dex, pair] = farm.sourceName.split(" ");
  const [farmToken, baseToken] = pair.split("-");
  const url = "https://app.alpacafinance.org" + farm.openPositionUrl;
  const { t } = useTranslation();
  return (
    <a
      className="flex flex-col lg:flex-row items-center justify-center"
      href={url}
    >
      <div className="flex flex-row items-center justify-center my-1 lg:my-0 ">
        <div className="text-xs lg:text-lg flex-shrink-0 mr-2">
          {t("Earn up to")}
        </div>
        <div className="flex text-base lg:text-2xl text-center mr-2 font-bold text-green-gradient">
          {Math.floor(farm.totalApy)}% APY
        </div>
        <div className="flex items-center flex-shrink-0">
          <div className="text-xs lg:text-lg mr-2">{t("on")}</div>
          <div className="grid justify-center mr-2 farm-pair">
            <img
              className="inline-block w-6 h-6 lg:w-8 lg:h-8"
              src={`https://alpaca-app-assets.alpacafinance.org/icons/coins/${farmToken.toLowerCase()}.svg`}
            ></img>
            <img
              className="inline-block w-6 h-6 lg:w-8 lg:h-8"
              src={`https://alpaca-app-assets.alpacafinance.org/icons/coins/${baseToken.toLowerCase()}.svg`}
            ></img>
          </div>
          <div className="flex flex-col">
            <div className="text-xs font-bold lg:text-base">{pair}</div>
            <div className="text-xs lg:text-base">{dex}</div>
          </div>
        </div>
      </div>
      {/* <div className="h-full hidden lg:block lg:w-[1px] lg:h-4/6 bg-green-gradient" /> */}
      <div className="h-[7em] w-px bg-green-gradient lg:block hidden lg:mx-[2em]"></div>
      <hr className="w-full border-1 border-green-gradient lg:hidden" />
      <div className="flex flex-row items-center justify-center lg:text-base text-green-gradient my-1 lg:my-0 lg:whitespace-nowrap ">
        Open position now{" "}
        <img
          className="inline h-2 lg:h-4 m-1.5"
          src={chevronRightIcon}
          alt="Chevron right"
          style={{ color: "white" }}
        />
      </div>
    </a>
  );
};

const Hero: React.FC = () => {
  const { t } = useTranslation();
  const { summary, moneyMarketSummary, automatedVaultV3Summary } =
    usePrepareData();

  const farmList = new Set([
    "PancakeSwap ALPACA-BUSD",
    // "Biswap BSW-BNB",
    // "Biswap USDC-BNB",
    // "Biswap BNB-BUSD",
    // "Biswap BNB-BTCB",
    // "Biswap ETH-USDT",
  ]);

  const farms: Array<IFarmingPool> = summary
    ? summary.farmingPools
        .filter((farm) => farmList.has(farm.sourceName))
        .sort((a, b) => b.totalApy - a.totalApy)
    : [];

  return (
    <section className="relative bg-hero-mobile md:bg-hero-desktop bg-cover bg-no-repeat bg-bottom overflow-hidden pt-28 pb-4 lg:pt-24 lg:overflow-visible">
      {/* <img className="absolute w-full z-0 top-0 left-0" src={heroBg} /> */}
      <div className="container lg:mx-auto lg:flex lg:justify-between">
        <div className="relative z-10 lg:w-2/3">
          <h1 className="text-green-gradient lg:leading-tight text-2xl lg:text-5xl font-black">
            {t("Leveraged yield farming")}
          </h1>
          <h2 className="mt-1 lg:mt-6 font-thin text-xl lg:text-4xl">
            by the alpacas, for the alpacas
          </h2>
          <div className="mt-6 flex lg:container lg:mx-auto lg:px-0">
            <img
              className="h-16 lg:h-auto mr-2 lg:mr-4"
              src={heroBorder}
              alt="hero border"
            />
            <div className="lg:inline">
              <div className="lg:hidden text-xs">{t("alpacaIntroMobile")}</div>
              <div className="hidden lg:inline text-base">
                {t("alpacaIntro")}
              </div>
              <Link
                to="https://docs.alpacafinance.org"
                className="block text-xs lg:text-base font-medium mt-4 lg:mt-2"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                {t("Learn more")}{" "}
                <img
                  className="inline"
                  src={chevronRightIcon}
                  alt="Chevron right"
                  style={{ color: "white" }}
                />
              </Link>
              <div className="hidden lg:flex flex-row gap-3 mt-6 w-full">
                <a href="https://app.alpacafinance.org">
                  <Button
                    className="tracking-wider w-[8.5rem] py-0.5"
                    appearance="fill"
                  >
                    {t("Launch AF1")}
                  </Button>
                </a>
                <a href="https://app-v2.alpacafinance.org/">
                  <Button
                    className="tracking-wider w-[8.5rem] py-0.5 highlight"
                    appearance="fill"
                  >
                    {t("Launch AF2")}
                  </Button>
                </a>
              </div>
            </div>
          </div>
          <div className="lg:hidden flex flex-row gap-3 mt-6 w-full">
            <a href="https://app.alpacafinance.org">
              <Button className="tracking-wider w-[8.2rem]" appearance="fill">
                {t("Launch AF1")}
              </Button>
            </a>
            <a href="https://app-v2.alpacafinance.org/">
              <Button
                className="tracking-wider w-[8.2rem] highlight"
                appearance="fill"
              >
                {t("Launch AF2")}
              </Button>
            </a>
          </div>
        </div>
      </div>
      <div className="relative mt-44 lg:mt-20 xl:mt-72 container flex flex-col lg:flex-row mx-auto gap-2 lg:gap-6 lg:justify-between">
        <div className="w-full lg:w-2/5 rounded-2xl border border-transparent bg-dark-500-border-green-gradient">
          <div className="w-full flex flex-col">
            <div className="flex flex-row items-center justify-center my-2.5 lg:justify-start lg:px-4">
              <div className="pl-2 lg:pl-4 font-bold text-xs lg:text-lg">
                TVL :
              </div>
              <div className="pl-2 lg:pl-4 text-lg lg:text-3xl font-bold text-green-gradient">
                {summary == null
                  ? "-"
                  : `$${numberWithCommas(
                      Math.floor(parseFloat(summary.overall.tvl))
                    )}`}
              </div>
              <div className="hidden lg:block lg:pl-2">
                <p className="text-xs font-thin">of funds deployed inside.</p>
              </div>
            </div>
            <hr className="border-green-gradient" />

            <div className="container flex flex-row px-2 lg:px-4 py-1.5 flex-start items-center gap-4">
              <div className="flex flex-row items-center">
                <div className="w-1.5 h-1.5 rounded-full bg-green-gradient ml-2 lg:ml-4"></div>
                <div className="pl-1 font-bold text-xs lg:text-sm">AF1.0</div>
              </div>
              <div className="flex flex-row items-center">
                <div className="text-sm lg:text-base">
                  {summary == null
                    ? "-"
                    : `$${numberWithCommas(
                        Math.floor(parseFloat(summary.overall.bscTvl))
                      )}`}
                </div>
                <div className="ml-1 lg:ml-2">
                  <img
                    className="h-4 lg:h-6"
                    src={
                      "https://alpaca-app-assets.alpacafinance.org/icons/coins/bnb.svg"
                    }
                    alt="Chevron right"
                    style={{ color: "white" }}
                  />
                </div>
                <div className="text-sm lg:text-base ml-1 lg:ml-2">
                  {t("+")}
                </div>
                <div className="text-sm lg:text-base ml-1 lg:ml-2">
                  {summary == null
                    ? "-"
                    : `$${numberWithCommas(
                        Math.floor(parseFloat(summary.overall.ftmTvl))
                      )}`}
                </div>
                <div className="ml-1 lg:ml-2">
                  <img
                    className="h-4 lg:h-6"
                    src={
                      "https://alpaca-app-assets.alpacafinance.org/icons/coins/ftm.svg"
                    }
                    alt="Chevron right"
                    style={{ color: "white" }}
                  />
                </div>
              </div>
            </div>
            <hr className="hidden lg:block" />
            <div className="container flex flex-row px-2 lg:px-4 pb-2 lg:py-2 flex-start items-center gap-3">
              <div className="flex flex-row items-center">
                <div className="w-1.5 h-1.5 rounded-full bg-green-gradient ml-2 lg:ml-4"></div>
                <div className="pl-1 font-bold text-xs lg:text-sm">AF2.0</div>
              </div>
              <div className="flex flex-row items-center">
                <div className="text-sm lg:text-base">
                  {moneyMarketSummary == null || automatedVaultV3Summary == null
                    ? "-"
                    : `$${numberWithCommas(
                        Math.floor(
                          parseFloat(moneyMarketSummary.totalTvl) +
                            parseFloat(automatedVaultV3Summary.totalTvl)
                        )
                      )}`}
                </div>
                <div className="ml-1 lg:ml-2">
                  <img
                    className="h-4 lg:h-6"
                    src={
                      "https://alpaca-app-assets.alpacafinance.org/icons/coins/bnb.svg"
                    }
                    alt="Chevron right"
                    style={{ color: "white" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-3/5 rounded-2xl border border-transparent bg-dark-500-border-green-gradient">
          <div className="h-full w-full">
            {farms.length > 0 && (
              <Slider
                className="h-full w-full flex flex-row justify-center items-center"
                autoplay
                autoplaySpeed={4000}
                arrows={false}
              >
                {farms.map((farm) => (
                  <FarmComponent key={farm.key} farm={farm} />
                ))}
              </Slider>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
