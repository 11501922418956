import React from "react";
import cx from "classnames";
import { Trans } from "react-i18next";
import squares from "~/images/graphics/squares.png";
import hex from "~/images/graphics/hex.svg";

import twitterIcon from "~/images/social-icons/twitter.svg";
import telegramIcon from "~/images/social-icons/telegram.svg";
import discordIcon from "~/images/social-icons/discord.svg";
import nftIcon from "~/images/social-icons/nft.png";
import githubIcon from "~/images/social-icons/github.svg";
import redditIcon from "~/images/social-icons/reddit.svg";
import youtubeIcon from "~/images/social-icons/youtube.svg";
import mediumIcon from "~/images/social-icons/medium.svg";
import texturedCircle from "~/images/graphics/textured-circle.svg";
import { useWindowSize } from "~/ui/hooks";

const items = [
  {
    label: "Twitter",
    icon: twitterIcon,
    alt: "twitter",
    href: "https://twitter.com/AlpacaFinance",
  },
  {
    label: "Telegram",
    icon: telegramIcon,
    alt: "telegram",
    href: "https://t.me/alpacafinance",
  },
  {
    label: "Discord",
    icon: discordIcon,
    alt: "discord",
    href: "https://discord.com/invite/jkC2j9ZVDf",
  },
  {
    label: "NFT",
    icon: nftIcon,
    alt: "nft",
    href: "https://galaxy.eco/alpacafinance/",
  },
  {
    label: "Github",
    icon: githubIcon,
    alt: "github",
    href: "https://github.com/alpaca-finance/bsc-alpaca-contract",
  },
  {
    label: "Reddit",
    icon: redditIcon,
    alt: "reddit",
    href: "https://www.reddit.com/r/AlpacaFinanceOfficial/",
  },
  {
    label: "YouTube",
    icon: youtubeIcon,
    alt: "youtube",
    href: "https://www.youtube.com/channel/UC8xBPBPgRD-xe_ZfyOwV_Dg",
  },
  {
    label: "Medium",
    icon: mediumIcon,
    alt: "medium",
    href: "https://medium.com/alpaca-finance",
  },
];

const Hex: React.FC<{ className?: string; href: string }> = ({
  className,
  children,
  href,
}) => (
  <a
    className={cx("relative inline-block w-full", className)}
    href={href}
    target="_blank"
    rel="nofollow noopener noreferrer"
  >
    <img className="w-full" src={hex} alt="Hexagon" />
    <div className="absolute inset-0 flex flex-col justify-center items-center">
      {children}
    </div>
  </a>
);

const r = 300;
const w = 233;
const da = (2 * Math.PI) / items.length;
const ps = [];
// eslint-disable-next-line no-plusplus
for (let i = 0; i < items.length; i++) {
  const x = Math.sin(i * da) * r - w * 0.5;
  const y = -Math.cos(i * da) * r - w * 0.5;
  ps.push({ x, y });
}

const JoinCommunity: React.FC = () => {
  const { width } = useWindowSize();
  return (
    <section
      className="pt-10 pb-40 lg:pb-10 bg-cover bg-center relative lg:h-community-section-desktop overflow-hidden lg:flex lg:items-center lg:justify-center"
      style={{ backgroundImage: `url(${squares})` }}
    >
      <img
        className="absolute z-0 bottom-0 right-0 transform translate-x-1/3 opacity-80 lg:hidden"
        aria-hidden
        src={texturedCircle}
        width={500}
        alt=""
      />
      <div className="container mx-auto mb-10 text-2xl font-medium text-center lg:text-3xl lg:font-semibold">
        <Trans i18nKey="joinCommunity">
          Join
          <br className="hidden lg:block" />
          Our Community
        </Trans>
      </div>
      <div
        className="container mx-auto text-center max-w-sm lg:max-w-none lg:absolute"
        style={{ left: "50%", top: "50%" }}
      >
        {items.map(({ alt, icon, href, label }, i) => (
          <div
            className={cx(
              "inline-block w-1/2 transform mb-3 last:mb-0 lg:absolute lg:translate-y-0 lg:scale-0 lg:top-0 lg:left-0",
              i % 2 === 0 && "origin-left scale-105",
              i % 2 !== 0 && "origin-right scale-105 translate-y-1/2"
            )}
            style={{
              transform:
                width >= 1024 ? `translate(${ps[i].x}px, ${ps[i].y}px)` : "",
              maxWidth: 223,
            }}
            key={alt}
          >
            <Hex href={href}>
              <img className="w-12" src={icon} alt={alt} />
              <div className="mt-4 font-semibold">{label}</div>
            </Hex>
          </div>
        ))}
      </div>
    </section>
  );
};
export default JoinCommunity;
