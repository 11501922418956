import { Link } from "gatsby-plugin-react-i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import alpacas from "~/images/graphics/alpacas.png";
import Button from "~/ui/Button";

const Final: React.FC = () => {
  const { t } = useTranslation();
  return (
    <section className="flex flex-col mt-16 lg:flex-row lg:items-stretch lg:mt-12">
      <img className="lg:hidden" src={alpacas} alt="Alpacas" />
      <div
        className="lg:block lg:w-1/2 bg-center bg-cover"
        style={{ backgroundImage: `url(${alpacas})` }}
      />
      <div className="container mx-auto py-6 lg:w-1/2 lg:max-w-none lg:pt-16 lg:pb-28 lg:px-14 bg-gradient-to-br from-dark-300 via-dark-400 to-dark-400">
        <div className="inline-block text-2xl lg:text-4xl lg:leading-loose lg:font-semibold">
          {t("Want to join the Herd?")}
        </div>
        <div
          className="mt-2 mb-16 text-lg lg:my-16 lg:text-2xl"
          style={{ maxWidth: 500 }}
        >
          {t(
            "We are always looking for talented and passionate alpacas to join us."
          )}
        </div>
        <Link to="https://forms.gle/4cRbgf5kRa4q4c777" target="_blank" rel="nofollow noopener noreferrer">
          <Button appearance="secondary" className="py-1 px-4">
            <span className="text-sm font-normal lg:text-2xl">
              {t("Contact Us")}
            </span>
          </Button>
        </Link>
      </div>
    </section>
  );
};
export default Final;
