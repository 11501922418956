import cx from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import chevronRightIcon from "~/images/icons/chevron-right.svg";

const items = [
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/binance.svg",
    label: "Binance",
    alt: "binance",
    href: "https://www.binance.com/en/trade/ALPACA_USDT",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/gate-io.svg",
    label: "Gate.io",
    alt: "gate.io",
    href: "https://www.gate.io/trade/ALPACA_USDT",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/coinstats.svg",
    label: "CoinStats",
    alt: "coinstats",
    href: "https://coinstats.app/refer/CoinStatsAffiliate?utm_source=CoinStatsAffiliate&utm_medium=aff&utm_campaign=inf&utm_id=CoinStatsAffiliate&fpr=huacaya24",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/pyth.svg",
    label: "Pyth Network",
    alt: "pyth network",
    href: "https://pyth.network/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/mexc.svg",
    label: "MEXC",
    alt: "mexc",
    href: "https://www.mexc.com/exchange/ALPACA_USDT",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/indodax.svg",
    label: "Indodax",
    alt: "indodax",
    href: "https://indodax.com/market/ALPACAIDR",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/tokocrypto.svg",
    label: "Tokocrypto",
    alt: "tokocrypto",
    href: "https://www.tokocrypto.com/en/trade/ALPACA_USDT",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/coindcx.svg",
    label: "CoinDCX",
    alt: "coindcx",
    href: "https://coindcx.com/trade/ALPACAUSDT",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/wazirx.svg",
    label: "WazirX",
    alt: "wazirx",
    href: "https://wazirx.com/exchange/ALPACA-INR",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/latoken.svg",
    label: "LATOKEN",
    alt: "latoken",
    href: "https://latoken.com/exchange/ALPACA_USDT",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/whitebit.svg",
    label: "WhiteBIT",
    alt: "whitebit",
    href: "https://whitebit.com/trade/ALPACA-USDT",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/lbank.svg",
    label: "LBank",
    alt: "lbank",
    href: "https://www.lbank.info/exchange/alpaca/usdt",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/coinex.svg",
    label: "CoinEx",
    alt: "coinex",
    href: "https://www.coinex.com/en/exchange/alpaca-usdt?currency=usdt&dest=alpaca&tab=limit",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/poloniex.svg",
    label: "Poloniex",
    alt: "poloniex",
    href: "https://poloniex.com/trade/ALPACA_USDT?type=spot",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/100x.svg",
    label: "100x",
    alt: "100x",
    href: "https://www.100x.fi/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/bsw.svg",
    label: "Biswap",
    alt: "biswap",
    href: "https://biswap.org/?utm_source=Alpaca&utm_medium=referral&utm_campaign=button_collab_BD",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/cake.svg",
    label: "PancakeSwap",
    alt: "pancakeswap",
    href: "https://pancakeswap.finance/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/mdex.svg",
    label: "Mdex",
    alt: "mdex",
    href: "https://mdex.com/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/spookyswap.svg",
    label: "SpookySwap",
    alt: "spookyswap",
    href: "https://spookyswap.finance/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/chainlink.svg",
    label: "Chainlink",
    alt: "chainlink",
    href: "https://chain.link/",
    logoBackground: "bg-white",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/tusd.svg",
    label: "TUSD",
    alt: "tusd",
    href: "https://www.trusttoken.com/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/immunefi.svg",
    label: "Immunefi",
    alt: "immunefi",
    href: "https://immunefi.com/bounty/alpacafinance/",
    logoBackground: "bg-white",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/NEXUS.svg",
    label: "Nexus Mutal",
    alt: "nexus mutal",
    href: "https://app.nexusmutual.io/cover/buy/get-quote?address=0xA625AB01B08ce023B2a342Dbb12a16f2C8489A8F",
    logoBackground: "bg-white",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/insurAce.svg",
    label: "InsurACE",
    alt: "insurace",
    href: "https://app.insurace.io/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/dodo.svg",
    label: "DODO",
    alt: "dodo",
    href: "https://dodoex.io/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/belt.svg",
    label: "Belt",
    alt: "belt",
    href: "https://belt.fi/bsc/",
    logoBackground: "bg-white",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/SCIX.svg",
    label: "Scientix",
    alt: "scientix",
    href: "https://scientix.finance/#/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/Fronter.svg",
    label: "Frontier",
    alt: "Frontier",
    href: "https://frontier.xyz/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/shinobi-wallet.svg",
    label: "Shinobi Wallet",
    alt: "shinobi wallet",
    href: "https://shinobi-wallet.net/",
    logoBackground: "bg-white",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/orbs.svg",
    label: "Orbs",
    alt: "orbs",
    href: "https://www.orbs.com/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/borring.svg",
    label: "Boring DAO",
    alt: "boring DAO",
    href: "https://www.boringdao.com/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/form.svg",
    label: "Formation",
    alt: "formation",
    href: "https://formation.fi/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/crown.svg",
    label: "Seascape",
    alt: "seascape",
    href: "https://www.seascape.network/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/swingby.svg",
    label: "Swingby",
    alt: "swingby",
    logoBackground: "",
    href: "https://swingby.network/",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/oddz.svg",
    label: "Oddz",
    alt: "oddz",
    href: "https://www.oddz.fi/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/berry.svg",
    label: "Berry Data",
    alt: "berry data",
    href: "https://www.boringdao.com/",
    logoBackground: "bg-white",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/icons/coins/cube.png",
    label: "CUBE",
    alt: "cube",
    href: "https://t.co/vIFLZoeS7Q",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/PolyChain.svg",
    label: "Polychain Monsters",
    alt: "polychain-monsters",
    href: "https://polychainmonsters.com/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/PHA.svg",
    label: "Phala Network",
    alt: "pha-network",
    href: "https://www.phala.network/en/",
    logoBackground: "bg-white",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/ALM.svg",
    label: "Alium Finance",
    alt: "alium-finance",
    href: "https://alium.finance/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/KALA.svg",
    label: "Kalata",
    alt: "kalata",
    href: "https://kalata.io/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/NAOS.svg",
    label: "NAOS Finance",
    alt: "naos-finance",
    href: "https://naos.finance/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/dvision.svg",
    label: "Dvision Network",
    alt: "dvision-network",
    href: "https://dvision.network/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/icons/coins/qbt.svg",
    label: "Qubit Finance",
    alt: "qubit-finance",
    href: "https://qbt.fi/app/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/icons/coins/pots.svg",
    label: "Moonpot",
    alt: "moonpot",
    href: "https://play.moonpot.com/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/icons/coins/polar.svg",
    label: "Polar Sync",
    alt: "polar-sync",
    href: "https://www.polarsync.app/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/icons/coins/moni.svg",
    label: "Monsta Infinite",
    alt: "monsta-infinite",
    href: "https://monstainfinite.com/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/icons/coins/bmon.svg",
    label: "Binamon",
    alt: "binamon",
    href: "https://binamon.org/",
    logoBackground: "bg-white",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/icons/coins/nfty.png",
    label: "NFTY Finance",
    alt: "nfty-finance",
    href: "https://www.nfty.finance/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/icons/coins/sheesha.svg",
    label: "Sheesha Finance",
    alt: "sheesha-finance",
    href: "https://www.sheeshafinance.io/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/icons/coins/skill.svg",
    label: "CryptoBlades",
    alt: "cryptoblades",
    href: "https://www.cryptoblades.io/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/mars.svg",
    label: "Mars Ecosystem",
    alt: "Mars Ecosystem",
    href: "https://marsecosystem.com/home",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/icons/coins/arv.svg",
    label: "ARIVA",
    alt: "ariva",
    href: "https://ariva.digital/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/leonicorn.svg",
    label: "Leonicorn Swap",
    alt: "leonicorn-swap",
    href: "https://www.leonicornswap.com/",
    logoBackground: "bg-white",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/icons/coins/wnow.svg",
    label: "WalletNow",
    alt: "wallet-now",
    href: "https://walletnow.net/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/icons/coins/dep.svg",
    label: "DEAPcoin",
    alt: "deapcoin",
    href: "https://dea.sg/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/icons/coins/tenfi.svg",
    label: "TEN Finance",
    alt: "ten-finance",
    href: "https://www.ten.finance/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/ternoa.svg",
    label: "Ternoa",
    alt: "ternoa",
    href: "https://www.ternoa.com/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/icons/coins/sps.svg",
    label: "Splinterlands",
    alt: "splinterlands",
    href: "https://splinterlands.com/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/land.svg",
    label: "Landshare",
    alt: "Landshare",
    href: "https://landshare.io/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/highstreet.svg",
    label: "Highstreet",
    alt: "highstreet",
    href: "https://www.highstreet.market/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/deltatheta.svg",
    label: "delta.theta",
    alt: "delta.theta",
    href: "https://deltatheta.tech/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/xworldgames.svg",
    label: "X World Games",
    alt: "xwg.games",
    href: "https://xwg.games/#/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/tinyworld.svg",
    label: "Tiny World",
    alt: "tiny.world",
    href: "https://tinyworlds.io/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/outerring.svg",
    label: "Outer Ring",
    alt: "outer-ring",
    href: "https://outerringmmo.com/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/bithotel.svg",
    label: "Bit Hotel",
    alt: "bit-hotel",
    href: "https://bithotel.io/#/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/gym-network.svg",
    label: "Gym Network",
    alt: "gym-network",
    href: "https://gymnetwork.io/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/pandora-spirit.svg",
    label: "Pandora Spirit",
    alt: "pandora-spirit",
    href: "https://pandora.digital/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-asset.s3.ap-southeast-1.amazonaws.com/partners/pstake.svg",
    label: "pSTAKE Finance",
    alt: "pstake-finance",
    href: "https://pstake.finance/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-assets.alpacafinance.org/icons/coins/the.svg",
    label: "Thena",
    alt: "thena",
    href: "https://www.thena.fi/",
    logoBackground: "",
  },
  {
    logo: "https://alpaca-app-assets.alpacafinance.org/partners/de-fi.svg",
    label: "De.Fi",
    alt: "de.fi",
    href: "https://de.fi/",
    logoBackground: "",
  },
];

const Arrow = ({ className, direction, onClick }) => (
  <button className={className} onClick={onClick} type="button">
    <img
      src={chevronRightIcon}
      alt="chevron"
      className={cx(
        "absolute z-10 top-1/2 transform -translate-y-1/2 w-5",
        direction === "left" ? "left-2 rotate-180" : "right-2"
      )}
    />
  </button>
);

const Partners: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section className="lg:relative">
      <div className="relative container mx-auto mt-8 lg:mt-16">
        <div className="relative px-4 lg:px-0 inline-block text-2xl font-medium lg:text-section-header-desktop mb-8 lg:mb-16">
          <div className="absolute header-background z-0 top-0 left-0 h-full w-full"></div>
          <span className="relative">{t("Our Partners")}</span>
        </div>
      </div>
      <div>
        <div className="grid grid-cols-2 container mx-auto gap-4 lg:grid-cols-4 lg:gap-2">
          {items.map(({ logo, label, alt, href, logoBackground }) => (
            <a
              className="col-span-1 p-4 border border-gray-100 bg-dark-500 rounded-xl lg:flex lg:items-center lg:py-3 lg:px-4"
              href={href}
              key={alt}
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              <img
                className={`w-11 lg:w-16 rounded-full ${logoBackground}`}
                src={logo}
                alt={alt}
              />
              <div className="mt-9 text-m lg:mt-0 lg:ml-4 lg:text-xl">
                {label}
              </div>
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Partners;
