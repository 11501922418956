import { graphql } from "gatsby";
import React from "react";
import Cool from "~/index-page/Cool";
import Final from "~/index-page/Final";
import Hero from "~/index-page/Hero";
import JoinCommunity from "~/index-page/JoinCommunity";
import Partners from "~/index-page/Partners";
import ProtocolSecurity from "~/index-page/ProtocolSecurity";
import LandingLayout from "~/landing/LandingLayout";
import Medias from "~/index-page/Medias";
import { PagesType } from "~/constants";
import './index.css'

const IndexPage: React.FC = () => (
  <LandingLayout pageType={PagesType.LANDINGPAGE}>
    <Hero />
    <Cool />
    <ProtocolSecurity />
    <JoinCommunity />
    <Partners />
    <Medias />
    <Final />
  </LandingLayout>
);

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
