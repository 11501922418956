import React, { useRef, useState } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import cool1 from "~/images/cools/cool-1.svg";
import cool2 from "~/images/cools/cool-2.svg";
import cool3 from "~/images/cools/cool-3.svg";

const items = [
  {
    icon: cool1,
    label: "Community Owned",
    extraClass: "",
    alt: "Community Owned",
  },
  {
    icon: cool2,
    label: "No Investor",
    extraClass:
      "flex-row-reverse border-image-green-gradient lg:border-l lg:border-r",
    alt: "No Investor",
  },
  {
    icon: cool3,
    label: "Fair launch",
    extraClass: "",
    alt: "Fair launch",
  },
];

const Cool: React.FC = () => {
  const { t } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState(0);
  const ref = useRef<any>();
  const next = () => {
    if (ref.current) ref.current.slickNext();
  };
  const previous = () => {
    if (ref.current) ref.current.slickPrev();
  };

  return (
    <section className="lg:relative py-10 lg:py-11">
      <div>
        <div className="grid grid-cols-1 container mx-auto gap-0 md:grid-cols-3 md:gap-0">
          {items.map(({ icon, extraClass, label, alt }) => (
            <div
              className={`col-span-1 items-center p-4 flex md:flex-col md:items-center md:py-6 md:px-8 ${extraClass}`}
              key={alt}
            >
              <img
                className="w-1/2 lg:w-full h-auto md:mx-auto"
                src={icon}
                alt={alt}
              />
              <div className="w-1/2 md:mt-9 text-center text-lg lg:mt-0 lg:text-2xl">
                {label}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Cool;
